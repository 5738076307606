import React from "react"
import TopWithContent from "./Top/TopWithContent"
import TopWithoutContent from "./Top/TopWithoutContent"

const Top = (props) => {
  const postData = props.postData
  return postData?.useHeaderVideo ? (
    <TopWithContent
      image={postData?.headerImage?.localFile?.childImageSharp?.fluid}
      imageY={{ start: "0vh", end: "20vh" }}
      alt={postData?.headerImage?.altText}
    >
      <video
        autoPlay
        muted
        preload="auto"
        loop
        playsInline
        poster={postData?.headerImage?.localFile?.localURL}
      >
        <source
          src={postData?.headerVideoWebm?.localFile?.localURL}
          type="video/webm"
        />
        <source
          src={postData?.headerVideoMp4?.localFile?.localURL}
          type="video/mp4"
        />
      </video>
    </TopWithContent>
  ) : (
    <TopWithoutContent
      image={postData?.headerImage?.localFile?.childImageSharp?.fluid}
      imageY={{ start: "0vh", end: "20vh" }}
      alt={postData?.headerImage?.altText}
    />
  )
}

export default Top
