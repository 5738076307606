import React from "react"

const getIframeByUrl = (url) => {
  let iframe

  if (url.includes("youtube")) {
    iframe = (
      <iframe
        loading="lazy"
        src={url}
        allowFullScreen
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        title="YouTube video player"
      ></iframe>
    )
  } else if (url.includes("spotify")) {
    iframe = (
      <iframe
        className="embed-responsive-item"
        src={url}
        allowFullScreen
        frameBorder="0"
        allowtransparency="allowtransparency"
        allow="encrypted-media"
      ></iframe>
    )
  } else if (url.includes("vimeo")) {
    iframe = <iframe loading="lazy" src={url} allowFullScreen></iframe>
  } else {
    iframe = <iframe loading="lazy" src={url} allowFullScreen></iframe>
  }

  return iframe
}

const PostContentEmbed = (props) => {
  const url = props.url
  return <div className="ratio ratio-16x9 mb-2">{getIframeByUrl(url)}</div>
}

export default PostContentEmbed
