import React, { useState, useRef } from "react"
import { StaticQuery, graphql } from "gatsby"
import JobListing from "./JobListing"

const Component = (props) => {
  const [municipality, setMunicipality] = useState("")
  const [occupation, setOccupation] = useState("")
  const jobMenuRef = useRef(null)

  const onUpdate = () => {
    const { current } = jobMenuRef
    const boundingRect = current.getBoundingClientRect()

    if (boundingRect.y < 0) {
      current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      })
    }
  }
  return (
    <StaticQuery
      query={graphql`
        query MyQuery {
          allMunicipalities(sort: { fields: taxonomypreferredlabel }) {
            nodes {
              taxonomydefinition
              taxonomyid
              taxonomypreferredlabel
              taxonomytype
            }
          }
          allJobTitles(sort: { fields: taxonomypreferredlabel }) {
            nodes {
              taxonomydefinition
              taxonomyid
              taxonomypreferredlabel
              taxonomytype
            }
          }
        }
      `}
      render={(data) => {

        // Had to change these fields for the query to work, but no longer getting any data. (Needs further investigation)

        // taxonomy_preferred_label to taxonomypreferredlabel
        // taxonomy_id to taxonomyid
        // taxonomy_type to taxonomytype
        // taxonomy_definition to taxonomydefinition

        console.log('allMunicipalities', data.allMunicipalities);
        console.log('allJobTitles', data.allJobTitles);
        return (
          <div ref={jobMenuRef} className="job-wrap pt-3">
            <h2 className="mb-3">Hitta ett jobb och flytta hit</h2>
            <div className="job-menu mb-4">
              <div className="row">
                <div className="col-6 pr-2">
                  <div className="input-group">
                    <select
                      aria-label="Välj ort"
                      className="custom-select form-select job-select"
                      value={municipality}
                      onChange={(event) => {
                        setMunicipality(event.target.value)
                      }}
                    >
                      <option value="">Välj ort</option>
                      {data.allMunicipalities.nodes.map((item, i) => (
                        <option
                          key={item.taxonomy_id + i}
                          value={item.taxonomy_id}
                        >
                          {item.taxonomy_preferred_label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-6 pl-2">
                  <div className="input-group">
                    <select
                      aria-label="Välj arbetsroll"
                      className="custom-select form-select jobtitle-select"
                      value={occupation}
                      onChange={(event) => {
                        setOccupation(event.target.value)
                      }}
                    >
                      <option value="">Välj arbetsroll</option>
                      {data.allJobTitles.nodes.map((item, i) => (
                        <option
                          key={item.taxonomy_id + i}
                          value={item.taxonomy_id}
                        >
                          {item.taxonomy_preferred_label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            {(municipality || occupation) && (
              <JobListing
                municipality={municipality}
                occupation={occupation}
                onUpdate={onUpdate}
                offset={0}
              />
            )}
          </div>
        )
      }}
    />
  )
}

export default Component
