import React from "react"

const SidebarContentList = (props) => {
  const { list, sidebarTextColor } = props

  return (
    <ul className="link-list list-group">
      {list.map((item) => (
        <li key={item.listItem.toString()} className="list-group-item" style={{ color: sidebarTextColor }}>
          {item.listItem}
        </li>
      ))}
    </ul>
  )
}

export default SidebarContentList
