import React, { useMemo, useState } from "react"
import { Navbar } from "react-bootstrap"
import { HamburgerSpin } from "react-animated-burgers"
import DynamicLink from "../DynamicLink"
import Logo from "../Logo"
import useExtraMenuQuery from "../../hooks/useExtraMenuQuery"
import useExtraMenuIntlQuery from "../../hooks/useExtraMenuIntlQuery"

const Component = (props) => {
  const { linkData, isInternational, location } = props
  const [showMenu, setShowMenu] = useState(false)
  const toggleMenu = () => setShowMenu(!showMenu)
  const extraMenu = useExtraMenuQuery()
  const extraMenuIntl = useExtraMenuIntlQuery()

  let currExtraMenu = false

  if (isInternational) {
    currExtraMenu = extraMenuIntl
  } else {
    currExtraMenu = extraMenu
  }

  // Grouped link data by titles
  const groupedLinkData = useMemo(() => {
    return linkData.reduce((acc, curr) => {
      if (curr.isTitle) {
        acc.push([curr]);
      } else if (acc.length) {
        acc[acc.length - 1].push(curr);
      }
      return acc;
    }, []);
  }, [linkData]);

  return (
    <Navbar
      expanded={showMenu}
      bsPrefix={`navbar navbar-front fixed-top ${showMenu ? "active" : ""}`}
      id="start-page-menu"
    >
      <div className="container-fluid pr-0">
        <DynamicLink
          ariaLabel="Smart Move"
          tabIndex="-1"
          className="navbar-brand"
          to={"/"}
        >
          <Logo style={{ height: "1rem" }}></Logo>
        </DynamicLink>
        <HamburgerSpin
          className="navbar-toggler ml-auto navbar-hamburger"
          aria-controls="basic-navbar-nav"
          isActive={showMenu}
          toggleButton={toggleMenu}
          barColor="#fff"
          buttonWidth={30}
          tabIndex="0"
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <div className="container-fluid safe-bottom">
            <div className="row">
              <h2 className="align-self-end">Meny</h2>
              {groupedLinkData.length > 0 ?
                groupedLinkData.map((group, groupIndex) => (
                  <div className="link-group" key={`group-${groupIndex}`}>
                    <div className="row">
                      {group
                        .filter((item) => item.menuLevel)
                        .map((item, i) => (
                          <div
                            key={item.menuLevel + "v" + i}
                            title={item.isTitle ? item.title : item.link.title}
                          >
                            <DynamicLink
                              to={item.isTitle ? location.pathname : item.link.url}
                              target={item.link?.target}
                            >
                              <h3 className={`${item.isTitle ? 'category-title' : ''}`}>
                                {item.isTitle ? item.title : `› ${item.link.title}`}
                              </h3>
                            </DynamicLink>
                          </div>
                        ))}
                    </div>
                  </div>
                )) : linkData && (
                  <div className="col-12 col-md-8">
                    <div className="row">
                      {linkData
                        .filter((item) => item.menuLevel)
                        .map((item, i) => (
                          <div
                            className="col-xs-12"
                            key={item.menuLevel + "v" + i}
                            title={item.link.title}
                          >
                            <DynamicLink
                              to={item.link.url}
                              target={item.link?.target}
                            >
                              <h3>› {item.link.title}</h3>
                            </DynamicLink>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              {currExtraMenu && (
                <div className="row bottom justify-content-center">
                  {currExtraMenu.map((menuItem) => (
                    <DynamicLink
                      target={menuItem?.target}
                      to={menuItem?.url}
                    >
                      <h3>{menuItem?.label}</h3>
                    </DynamicLink>
                  ))}
                </div>
              )}
            </div>
          </div>
        </Navbar.Collapse>
      </div>
    </Navbar>
  )
}

export default Component
