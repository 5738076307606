import React from 'react'

const PostContentBackground = ({ color }) => {
  return (
    <div style={
      {
        position: 'absolute',
        left: '-10vw',
        bottom: 0,
        top: 0,
        background: color,
        width: '110vw',
        zIndex: -1,
      }
    } />
  )
}

export default PostContentBackground
