import React, { useRef } from "react"
import { graphql } from "gatsby"
import Top from "../components/Post/Top"
import PostContent from "../components/Post/PostContent"
import Layout from "../components/Layout"
import Sidebar from "../components/Post/Sidebar"
import { useAppState } from "../components/Context"
import Seo from "gatsby-plugin-wpgraphql-seo"
import PostContentBackground from "../components/Post/PostContent/PostContentBackground"
import useWindowDimensions from "../hooks/useWindowDimensions"

const PostTemplate = (props) => {
  const { data } = props
  const postData = data.wpPost.postData
  const postMeta = data.wpPost.postMeta
  const color = postData.sidebarHeaderColor
  const isSidebarContent = props.data.wpPost.postData.sidebarHeader
  const { width } = useWindowDimensions();
  const { currentLinkPage } = useAppState();
  const containerRef = useRef(null);

  const isDesktopWidth = width > 992;

  const isInternational = data.wpPost.categories.nodes.find((node) => node.name.toLowerCase() === 'international');
  const postCategories = data.wpPost.categories.nodes.map((node) => node.name);

  return (
    <>
      <Seo post={data.wpPost} />
      <Layout
        location={props.location}
        title={postMeta.title}
        description={postMeta.desc}
        image={postData?.headerImage?.localFile?.childImageSharp?.fluid}
        backLink={currentLinkPage}
        isInternational={isInternational}
        postCategories={postCategories}
      >
        <Top postData={postData} />
        <div className={`${isSidebarContent ? 'container-fluid' : ''} ${postData?.underlineLinks ? 'smartmove' : ''}`}>
          <div className="row justify-content-center overflow-hidden">
            <div ref={containerRef} className={`${isSidebarContent ? 'col-lg-10 content' : ''}`}>
              <div className="row">
                <div className={`col-10 ${isSidebarContent ? 'col-lg-8 content-width' : ''}`} style={{ position: 'relative' }}>
                  {postData?.primaryPostHeader && <h1 className="primary-post-header" style={{ color: postData?.postHeaderTextColor || 'unset' }}>{postData.primaryPostHeader}</h1>}
                  {postData?.primaryPostHeader
                    ? <h2 className="post-header" style={{ color: postData?.postHeaderTextColor || 'unset' }}>{postData.postHeader}</h2>
                    : <h1 style={{ color: postData?.postHeaderTextColor || 'unset' }}>{postData.postHeader}</h1>
                  }
                  {postData?.postHeaderBackgroundColor && <PostContentBackground color={postData.postHeaderBackgroundColor} />}
                </div>
                <div className={`col-12 ${isSidebarContent ? 'col-lg-8 content-width' : ''}`}>
                  <PostContent postContent={postData.postContent} containerWidth={containerRef?.current?.offsetWidth} isDesktopWidth={isDesktopWidth} />
                </div>
                <Sidebar color={color} sidebar={props.data.wpPost.postData} isDesktopWidth={isDesktopWidth} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default PostTemplate

export const postQuery = graphql`
  query ($id: String!) {
    wpPost(id: { eq: $id }) {
      postData {
        postContent {
          ... on WpPost_Postdata_PostContent_PostContentLead {
            fieldGroupName
            postContentLeadBackgroundColor
            postContentLeadText
            postContentLeadTextColor
          }
          ... on WpPost_Postdata_PostContent_PostContentWysiwyg {
            fieldGroupName
            postContentWysiwygEditor
            postContentWysiwygBackgroundColor
            postContentWysiwygHeaderColor
            postContentWysiwygTextColor
          }
          ... on WpPost_Postdata_PostContent_PostContentJobFinder {
            fieldGroupName
          }
          ... on WpPost_Postdata_PostContent_PostContentPersons {
            fieldGroupName
            postContentPersonRows {
              postContentPersonEditor
              postContentPersonImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: FULL_WIDTH
                      placeholder: TRACED_SVG
                      quality: 85
                    )
                  }
                }
                altText
              }
              fieldGroupName
            }
          }
          ... on WpPost_Postdata_PostContent_ParallaxMedia {
            backgroundColor
            fieldGroupName
            title
            textColor
            mediaType
            videoAutoplay
            mediaType
            videoLoop
            videoFile {
              sourceUrl
              localFile {
                localURL
              }
            }
            imageFile {
              altText
              localFile {
                childImageSharp {
                  fluid(
                    maxWidth: 4800
                    quality: 85
                    toFormat: JPG
                    srcSetBreakpoints: [10, 7200]
                  ) {
                    ...GatsbyImageSharpFluid_withWebp
                    presentationWidth
                    presentationHeight
                  }
                }
                localURL
              }
            }
          }
          ... on WpPost_Postdata_PostContent_PostContentHalfImages {
            fieldGroupName
            postContentHalfImageRows {
              postContentHalfImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: FULL_WIDTH
                      placeholder: BLURRED
                      quality: 85
                    )
                  }
                }
                altText
              }
              fieldGroupName
            }
          }
          ... on WpPost_Postdata_PostContent_PostContentBrokers {
            fieldGroupName
            postContentBrokerRows {
              postContentBrokerEditor
              postContentBrokerImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: FULL_WIDTH
                      placeholder: BLURRED
                      quality: 85
                    )
                  }
                }
                altText
              }
              fieldGroupName
            }
          }
          ... on WpPost_Postdata_PostContent_PostContentGrid {
            fieldGroupName
            postContentGridRows {
              postContentGridRowHeader
              postContentGridRowLink {
                url
                title
                target
              }
              fieldGroupName
            }
          }
          ... on WpPost_Postdata_PostContent_PostContentImage {
            fieldGroupName
            postContentImageField {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    quality: 85
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
          ... on WpPost_Postdata_PostContent_PostContentEmbed {
            fieldGroupName
            postContentEmbedUrl
          }
          ... on WpPost_Postdata_PostContent_RelatedArticles {
            fieldGroupName
            relatedArticle {
              ... on WpPost {
                id
                title
                uri
                excerpt
                featuredImage {
                  node {
                    altText
                    sourceUrl
                    uri
                    # sizes(size: MEDIUM_LARGE)
                  }
                }
              }
            }
          }
        }
        headerImage {
          localFile {
            childImageSharp {
              fluid(
                maxWidth: 4800
                quality: 85
                toFormat: JPG
                srcSetBreakpoints: [10, 7200]
              ) {
                ...GatsbyImageSharpFluid_withWebp
                presentationWidth
                presentationHeight
              }
            }
            localURL
          }
          altText
        }
        sidebarHeaderColor
        sidebarHeader
        sidebarTextColor
        sidebarContent {
          ... on WpPost_Postdata_SidebarContent_SidebarContentTable {
            fieldGroupName
            sidebarTable {
              rightCell
              leftCell
            }
          }
          ... on WpPost_Postdata_SidebarContent_SidebarContentList {
            fieldGroupName
            sidebarList {
              listItem
              fieldGroupName
            }
          }
          ... on WpPost_Postdata_SidebarContent_SidebarContentNav {
            fieldGroupName
            sidebarContentNavTitle
            sidebarNav {
              link {
                url
                title
                target
              }
            }
          }
          ... on WpPost_Postdata_SidebarContent_SidebarContentText {
            fieldGroupName
            sidebarTextarea
          }
        }
        postHeader
        postHeaderBackgroundColor
        postHeaderTextColor
        primaryPostHeader
        headerVideoWebm {
          localFile {
            localURL
          }
        }
        headerVideoMp4 {
          localFile {
            localURL
          }
        }
        useHeaderVideo
        underlineLinks
      }
      postMeta {
        title
        desc
      }
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      categories {
        nodes {
          name
        }
      }
    }
  }
`
