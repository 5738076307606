import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const PostContentHalfImage = (props) => {
  return (
    <div className="row images">
      {props.content.map((halfImageRow) => {
        return (
          <div className="col-6">
            {halfImageRow?.postContentHalfImage?.localFile && (
              <GatsbyImage
                alt={halfImageRow.postContentHalfImage?.altText}
                image={getImage(halfImageRow.postContentHalfImage?.localFile)}
              />
            )}
          </div>
        )
      })}
    </div>
  )
}

export default PostContentHalfImage
