import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import { HamburgerSpin } from "react-animated-burgers"
import Logo from "./Logo"

const Component = (props) => {
  const { backLink } = props
  const [posX, setPosX] = useState(0)
  useEffect(() => {
    setPosX(parseFloat(localStorage.getItem("posX")) || 0)
  }, [])
  return (
    <nav className="navbar fixed-top navbar-expand-sm navbar-light">
      <div className="container-fluid pr-0">
        <Link
          aria-label="Smart Move"
          tabIndex="-1"
          className="navbar-brand"
          state={{ posX: posX }}
          to={backLink}
        >
          <Logo />
        </Link>
        <ul className="navbar-nav ms-auto">
          <li className="nav-item">
            <Link
              className="nav-link pe-0 ps-0 navbar-cross"
              state={{ posX: posX }}
              to={backLink}
            >
              <motion.div
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.5 }}
                transition={{ duration: 0.2 }}
              >
                <HamburgerSpin
                  className="navbar-hamburger"
                  isActive={true}
                  barColor="#000"
                  buttonWidth={30}
                  tabIndex="-1"
                />
              </motion.div>
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  )
}
Component.defaultProps = {
  backLink: "/",
}

export default Component
