import React from "react"
import parse from "html-react-parser"
import PostContentBackground from "./PostContentBackground"

const PostContentWysiwyg = (props) => {
  const { content } = props
  return (
    <div className="wysiwyg" style={{ color: props.content.postContentWysiwygTextColor || "unset" }}>
      <PostContentBackground color={props.content.postContentWysiwygBackgroundColor || "unset"} />
      {parse(content?.postContentWysiwygEditor || '')}
    </div >
  )
}

export default PostContentWysiwyg
