import React from 'react'
import PostContentBackground from './PostContentBackground'

const PostContentFluidWrapper = ({ children }) => {
  return (
    <div className='post-content-fluid-wrapper'>
      <PostContentBackground color="#E8E8E8" />
      <div className="col-12">
        {children}
      </div>
    </div>
  )
}

export default PostContentFluidWrapper
