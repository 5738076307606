import React from "react"
import DynamicLink from "../../DynamicLink"
import parse from "html-react-parser";
import PostContentFluidWrapper from "./PostContentFluidWrapper";

const PostContentRelatedArticles = (props) => {
  return (
    <PostContentFluidWrapper>
      <div className="related-articles" style={{ minWidth: props.containerWidth }}>
        <div className="content">
          <h2>Relaterade artiklar</h2>
          <div className="row items">
            {props.content?.map((article) => (
              <DynamicLink to={article?.uri} className="article">
                <img src={article?.featuredImage?.node?.sourceUrl} />
                <div className="article-container">
                  <h3>{article?.title}</h3>
                  {parse(article?.excerpt || '')}
                </div>
              </DynamicLink>
            ))}
          </div>
        </div>
      </div>
    </PostContentFluidWrapper>
  )
}

export default PostContentRelatedArticles
