import React, { useRef } from "react"
import PostContentParallaxMediaTop from "./PostContentParallaxMediaTop";

const PostContentParallaxMedia = (props) => {
  const ref = useRef(null);

  const mediaType = props.content?.mediaType;
  const image = props.content?.imageFile?.localFile?.childImageSharp?.fluid;
  const video = props.content?.videoFile?.localFile?.localURL;

  return (
    <div className="post-content-parallax-media">
      <div ref={ref} className="parallax-container"
        style={{ height: props.isDesktopWidth ? '70vh' : '60vh' }}
      >
        <PostContentParallaxMediaTop
          media={mediaType === 'image' ? image : video}
          mediaHeight={props.isDesktopWidth ? '95vh' : '85vh'}
          mediaType={mediaType}
          alt={props.content?.imageFile?.altText}
          mediaY={{
            start: '-20vh',
            end: "3vh"
          }}
        />
        <div className="parallax-text-container" style={{ width: props.containerWidth }}>
          <h2 style={{ color: props.content?.textColor }}>
            <span style={{ backgroundColor: props.content?.backgroundColor }}>{props.content?.title}</span>
          </h2>
        </div>
      </div>
    </div>
  )
}

export default PostContentParallaxMedia
