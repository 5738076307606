import React, { useRef, useState, useEffect } from "react"
import Img from "gatsby-image"
import { motion, useViewportScroll, useTransform } from "framer-motion"
import useWindowDimensions from "../../../hooks/useWindowDimensions"

const Component = (props) => {
  const { media, mediaHeight, mediaType } = props
  const { scrollY } = useViewportScroll()
  const mediaRef = useRef(null)
  const [mediaTop, setMediaTop] = useState(0)
  const [mediaBot, setMediaBot] = useState(0)
  const { width, height } = useWindowDimensions()

  // useEffect(() => {
  //   const media = mediaRef.current.getBoundingClientRect()
  //   const offset = window.pageYOffset || 0
  //   setMediaTop(media.top + offset)
  //   setMediaBot(media.bottom + window.innerHeight + offset)
  // }, [width, height, mediaRef])
  useEffect(() => {
    const mediaRect = mediaRef.current.getBoundingClientRect()
    const offset = window.pageYOffset || 0
    setMediaTop(mediaRect.top + offset - window.innerHeight) // Start when the media is just about to enter the viewport
    setMediaBot(mediaRect.bottom + offset) // End when the media is just leaving the viewport
  }, [width, height, mediaRef])

  const x = useTransform(
    scrollY,
    [mediaTop, mediaBot],
    [props.mediaX.start, props.mediaX.end]
  )

  const y = useTransform(
    scrollY,
    [mediaTop, mediaBot],
    [props.mediaY.start, props.mediaY.end]
  )

  return (
    <div className='top-wrap parallax-media' ref={mediaRef}>
      <div className="media">
        <motion.div
          className="media-wrap"
          style={{
            padding: props.mediaOffset.join(" "),
            translateX: x,
            translateY: y,
          }}
        >
          {mediaType === 'image' && media && (
            <Img
              alt={props.alt}
              loading="eager"
              objectFit="contain"
              objectPosition="50% 50%"
              style={{
                height: mediaHeight || "100%",
              }}
              imgStyle={{
                objectFit: "cover",
                objectPosition: "50% 50%",
              }}
              fluid={media}
            />
          )}
          {mediaType === 'video' && media && (
            <video
              autoPlay
              muted
              loop
              style={{
                height: mediaHeight || "100%",
                objectFit: "cover",
                objectPosition: "50% 50%",
                minWidth: '100%',
              }}
              src={media}
            />
          )}
        </motion.div>
      </div>
    </div>
  )
}

Component.defaultProps = {
  mediaX: { start: 0, end: 0 },
  mediaY: { start: 0, end: 0 },
  zoom: { start: 0, end: 0 },
  mediaOffset: [null],
  alt: "",
  mediaType: 'image',
}

export default Component
