import React from "react"

const SidebarContentTable = (props) => {
  const { sidebarTable, sidebarTextColor } = props

  return (
    <table className="table" style={{ color: sidebarTextColor }}>
      <tbody>
        {sidebarTable.map((item) => (
          <tr key={item.leftCell.toString() + item.leftCell.toString()}>
            <td>{item.leftCell}</td>
            <td>{item.rightCell}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default SidebarContentTable
