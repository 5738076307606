import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const PostContentImage = (props) => {
  const image = props.image
  return (
    <div className="row images">
      <div className="col-12">
        {image?.localFile && (
          <GatsbyImage image={getImage(image.localFile)} alt={image.altText} />
        )}
      </div>
    </div>
  )
}

export default PostContentImage
