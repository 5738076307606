import React from "react"
import DynamicLink from "../../DynamicLink"

const SidebarContentNav = (props) => {
  const { sidebarContent, sidebarTextColor } = props

  return (
    <nav className="link-list list-group" style={{ color: sidebarTextColor }}>
      {sidebarContent?.sidebarContentNavTitle && (
        <div className="list-group-item" style={{ color: sidebarTextColor }}>
          {sidebarContent.sidebarContentNavTitle}
        </div>
      )}
      {sidebarContent?.sidebarNav &&
        sidebarContent.sidebarNav.map((navItem) => (
          <DynamicLink
            key={navItem.link?.title?.toString()}
            to={navItem.link?.url}
            target={navItem.link?.target}
            inline={false}
            color={sidebarTextColor}
          >
            {navItem.link?.title}
          </DynamicLink>
        ))}
    </nav>
  )
}

export default SidebarContentNav
