import React from "react"
import PostContentWysiwyg from "./PostContent/PostContentWysiwyg"
import PostContentLead from "./PostContent/PostContentLead"
import PostContentJobFinder from "./PostContent/PostContentJobFinder"
import PostContentPersons from "./PostContent/PostContentPersons"
import PostContentHalfImages from "./PostContent/PostContentHalfImages"
import PostContentBrokers from "./PostContent/PostContentBrokers"
import PostContentGrid from "./PostContent/PostContentGrid"
import PostContentImage from "./PostContent/PostContentImage"
import PostContentEmbed from "./PostContent/PostContentEmbed"
import PostContentRelatedArticles from "./PostContent/PostContentRelatedArticles"
import PostContentParallaxMedia from "./PostContent/PostContentParallaxMedia"

const getPostContentItemComponent = (postContentItem, containerWidth, isDesktopWidth, i) => {
  let component
  const key = postContentItem.fieldGroupName + "_" + i

  switch (postContentItem.fieldGroupName) {
    case "Post_Postdata_PostContent_PostContentLead":
      component = (
        <PostContentLead
          key={key}
          content={postContentItem}
        />
      )
      break

    case "Post_Postdata_PostContent_PostContentWysiwyg":
      component = (
        <PostContentWysiwyg
          key={key}
          content={postContentItem}
        />
      )
      break

    case "Post_Postdata_PostContent_PostContentJobFinder":
      component = <PostContentJobFinder key={key} />
      break

    case "Post_Postdata_PostContent_PostContentPersons":
      component = (
        <PostContentPersons
          key={key}
          content={postContentItem?.postContentPersonRows}
        />
      )
      break

    case "Post_Postdata_PostContent_PostContentHalfImages":
      component = (
        <PostContentHalfImages
          key={key}
          content={postContentItem?.postContentHalfImageRows}
        />
      )
      break

    case "Post_Postdata_PostContent_PostContentBrokers":
      component = (
        <PostContentBrokers
          key={key}
          content={postContentItem?.postContentBrokerRows}
        />
      )
      break

    case "Post_Postdata_PostContent_PostContentGrid":
      component = (
        <PostContentGrid
          key={key}
          content={postContentItem?.postContentGridRows}
        />
      )
      break

    case "Post_Postdata_PostContent_PostContentImage":
      component = (
        <PostContentImage
          key={key}
          image={postContentItem?.postContentImageField}
        />
      )
      break

    case "Post_Postdata_PostContent_PostContentEmbed":
      component = (
        <PostContentEmbed
          key={key}
          url={postContentItem?.postContentEmbedUrl}
        />
      )
      break

    case "Post_Postdata_PostContent_ParallaxMedia":
      component = (
        <PostContentParallaxMedia
          key={key}
          content={postContentItem}
          containerWidth={containerWidth}
          isDesktopWidth={isDesktopWidth}
        />
      )
      break

    case "Post_Postdata_PostContent_RelatedArticles":
      component = (
        <PostContentRelatedArticles
          key={key}
          content={postContentItem?.relatedArticle}
          containerWidth={containerWidth}
        />
      )
      break

    default:
      component = ""
      break
  }

  return component
}

const PostContent = (props) => {
  const { postContent, containerWidth, isDesktopWidth } = props

  return postContent
    ? postContent?.map((postContentItem, i) =>
      getPostContentItemComponent(postContentItem, containerWidth, isDesktopWidth, i)
    )
    : ""
}

export default PostContent
