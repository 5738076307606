import React from "react"
import DynamicLink from "../../DynamicLink"

const PostContentBrokers = (props) => {
  return (
    <div className="row items">
      {props.content.map((gridRow) => {
        return (
          <div
            className="col-6 col-md-4"
            key={gridRow?.postContentGridRowLink.title.toString()}
          >
            <h3>{gridRow?.postContentGridRowHeader}</h3>
            <DynamicLink
              inline
              target={gridRow?.postContentGridRowLink.target}
              href={gridRow?.postContentGridRowLink.url}
            >
              {gridRow?.postContentGridRowLink.title}
            </DynamicLink>
          </div>
        )
      })}
    </div>
  )
}

export default PostContentBrokers
