import React, { useState, useEffect } from "react"
import { useAsync, IfPending, IfFulfilled, IfRejected } from "react-async"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import Moment from "react-moment"
import "moment/locale/sv"

function objToQueryString(obj) {
  const keyValuePairs = []
  for (const key in obj) {
    keyValuePairs.push(
      encodeURIComponent(key) + "=" + encodeURIComponent(obj[key])
    )
  }
  return keyValuePairs.join("&")
}

// You can use async/await or any function that returns a Promise
const loadJobs = async ({ municipality, occupation, offset }, { signal }) => {
  const queryString = objToQueryString({
    municipality: municipality,
    offset: offset,
    occupation: occupation,
  })
  const res = await fetch(
    `https://toolbox.thesmartmove.se/wp-json/jobs/v1/jobs/?${queryString}`,
    { signal }
  )
  if (!res.ok) throw new Error(res.statusText)
  return res.json()
}

const Job = ({ data }) => {
  const link = data.application_details.url
    ? data.application_details.url
    : `https://arbetsformedlingen.se/platsbanken/annonser/${data.id}`
  return (
    <div className="card card-job mb-3">
      <OutboundLink
        href={link}
        target="_blank"
        rel="noreferrer"
        className="stretched-link"
      >
        <div className="row no-gutters">
          <div className="col-md-8 left">
            <div className="card-body">
              <h5 className="card-title job-title">{data.headline}</h5>

              <p className="card-text job-workplace">
                {data.employer.workplace}
                {" - "}
                {data.workplace_address.municipality}
              </p>
              <p className="card-text job-hours">
                {data.working_hours_type.label + ", " + data.duration.label}
              </p>
              <p className="card-text job-occupation">
                {data.occupation_field.label}
              </p>
            </div>
          </div>
          <div className="col-md-4 right">
            <div className="card-body">
              <p className="card-text job-date">
                Ansök senast:{" "}
                <Moment
                  format="Do MMMM"
                  locale="sv"
                  date={data.application_deadline}
                />
              </p>
            </div>
          </div>
        </div>
      </OutboundLink>
    </div>
  )
}

const Loading = ({ hits }) => (
  <div className="card-wrap">
    {[...Array(Math.min(50, Math.max(hits, 1)))].map((e, i) => (
      <div className="card card-job card-temp mb-3" key={"temp" + i}>
        <div className="row no-gutters">
          <div className="col-md-8">
            <div className="card-body">
              <div className="ph-item p-0">
                <div className="ph-col-12 p-0">
                  <div className="ph-row m-0">
                    <div className="ph-col-10 card-title big"></div>
                    <div className="ph-col-6"></div>
                    <div className="ph-col-12 big empty"></div>
                    <div className="ph-col-6"></div>
                    <div className="ph-col-6 empty"></div>
                    <div className="ph-col-4"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card-body">
              <div className="ph-item p-0">
                <div className="ph-col-12 p-0">
                  <div className="ph-row m-0">
                    <div className="ph-col-2 empty d-none d-md-block"></div>
                    <div className="ph-col-10 d-none d-md-block"></div>
                    <div className="ph-col-6 d-md-none"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ))}
  </div>
)

const Pagination = ({ offset, hits, onChangePage }) => {
  const perPage = 50
  const totalPages = Math.ceil(hits / perPage)

  const handleClick = (e) => {
    onChangePage(e.target.value)
  }
  return (
    <>
      {hits > perPage && (
        <nav aria-label="Navigera jobben">
          <ul className="pagination overflow-hidden">
            {[...Array(totalPages)].map((e, i) => (
              <li
                className={`page-item ${Math.abs(offset) === i && "active"}`}
                key={"pagination" + i}
              >
                <button className={`page-link`} value={i} onClick={handleClick}>
                  {i + 1}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      )}
    </>
  )
}

const Component = ({ municipality, occupation, onUpdate }) => {
  const [offset, setOffset] = useState(0)
  const state = useAsync({
    promiseFn: loadJobs,
    municipality: municipality,
    occupation: occupation,
    offset: offset,
  })

  const [jobData, setJobData] = useState({ total: { value: 0 }, hits: [] })

  useEffect(() => {
    setOffset(0)
    updateJobs()
  }, [municipality, occupation])

  useEffect(() => {
    updateJobs({ offset: offset })
    onUpdate()
  }, [offset])

  const updateJobs = (data) => {
    state.reload(data)
  }

  const changePage = (data) => {
    setOffset(data)
  }
  const setData = (data) => {
    setJobData(data)
  }
  return (
    <div>
      <IfPending state={state}>
        <Loading hits={jobData.total.value} />
      </IfPending>
      <IfRejected state={state}>
        {(error) => `Något gick fel: ${error.message}`}
      </IfRejected>
      <IfFulfilled state={state}>
        {(data) => {
          setData(data)
          return (
            <>
              <div className="joblist">
                {jobData.hits.map((data) => (
                  <Job data={data} key={data.id} />
                ))}
                {jobData.total.value === 0 && (
                  <div className="card card-job mb-3">
                    <div className="row no-gutters">
                      <div className="col-12">
                        <div className="card-body text-center">
                          <p className="mt-5 mb-5">
                            Det finns inga jobb med dom valen.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <Pagination
                offset={offset}
                hits={jobData.total.value}
                onChangePage={changePage}
              />
            </>
          )
        }}
      </IfFulfilled>
    </div>
  )
}
export default Component
