import React from "react"
import PostContentBackground from "./PostContentBackground"

const PostContentLead = (props) => {
  const { content } = props
  return (
    <div style={{
      position: 'relative',
      display: 'flex',
      backgroundColor: content?.postContentLeadBackgroundColor || 'unset',
      color: content?.postContentLeadTextColor || 'unset',
    }}
    >
      {content?.postContentLeadBackgroundColor && <PostContentBackground color={content.postContentLeadBackgroundColor} />}
      <p className="ingress">{content?.postContentLeadText}</p>
    </div>
  )
}

export default PostContentLead
